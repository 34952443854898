import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Input, Box, Button } from "@quarkly/widgets";
import QuarklycommunityKitForm from "./QuarklycommunityKitForm";
const defaultProps = {};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"gap": "16px",
			"display": "grid",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "16px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"sm-width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "Name"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"name": "name",
			"type": "text"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"sm-width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "Email"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"type": "email",
			"name": "email"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"grid-column": "1 / span 2"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"margin": "0 0 4px 0",
			"children": "Message"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"as": "textarea",
			"rows": "4",
			"width": "100%",
			"name": "message"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"grid-column": "1 / span 2"
		}
	},
	"button": {
		"kind": "Button",
		"props": {}
	}
};

const ContactForm = props => {
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleSubmit = event => {
		event.preventDefault(); // Prevent default form submit action
		// Collect form data

		const formData = new FormData(event.target);
		fetch('/submit', {
			method: 'POST',
			body: formData
		}).then(response => {
			if (response.ok) {
				return response.json();
			} else {
				throw new Error('Network response was not ok.');
			}
		}).then(data => {
			// Handle the response data here
			setSuccessMessage('Form submitted successfully!');
			setErrorMessage('');
			setIsSubmitted(true);
		}).catch(error => {
			// Handle the error here
			setErrorMessage('Failed to submit the form. Please try again.');
			setSuccessMessage('');
		});
	};

	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitForm onSubmit={handleSubmit} {...rest}>
		            
		{!isSubmitted ? <Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Input {...override("input")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text1")} />
				<Input {...override("input1")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text2")} />
				<Input {...override("input2")} />
			</Box>
			<Box {...override("box4")}>
				<Button {...override("button")}>
					Send
				</Button>
			</Box>
			                
		</Box> : <Box {...override("box")}>
			                    
			{successMessage && <Text color="green">
				{successMessage}
			</Text>}
			                    
			{errorMessage && <Text color="red">
				{errorMessage}
			</Text>}
			                
		</Box>}
		{children}
	</QuarklycommunityKitForm>;
};

Object.assign(ContactForm, { ...QuarklycommunityKitForm,
	defaultProps,
	overrides
});
export default ContactForm;